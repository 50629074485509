import React from "react";
import PropTypes from 'prop-types';

import ProductCard from '../ProductCard/ProductCard'

export default function ProductGrid( props ) {

	return (
		<div className="product-grid">
			<div className="product-grid__wrapper">
				{ props.products.map(( product, index ) => (
					<React.Fragment key={ product.id }>
						<ProductCard product={ product } showCartButton={ true } index={ index } setCount={ props.setCount } count={ props.count }/>
					</React.Fragment>
				) ) }
			</div>
		</div>
	)
}

ProductGrid.propTypes = {
	products: PropTypes.array
}