import React, { useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function CarouselSlide ( props ) {

	const { slide, slideIndex, currentSlide, totalImages } = props
	const [ translateValue, setTranslateValue ] = useState()
	const [ nextSlide, setNextSlide ] = useState( false )
	const [ previousSlide, setPreviousSlide ] = useState( false )
	const [ isCurrentSlide, setIsCurrentSlide ] = useState()

	/**
	 * Allows images to infinite loop when incremented/numbers are negative, image at correct positive index returned
	 * @param {Number} imageIndex - images at index
	 * @param {Number} totalImages -- total number of images
	 */
	const modulus = ( imageIndex, totalImages ) => {
		return ( ( imageIndex % totalImages ) + totalImages ) % totalImages
	}

	useEffect(() => {

		const setSlide = ( transitionValue, nextSlide, previousSlide, isCurrentSlide ) => {
			setTranslateValue( transitionValue )
			setNextSlide( nextSlide )
			setPreviousSlide( previousSlide )
			setIsCurrentSlide( isCurrentSlide )
		}

		if ( currentSlide === slideIndex ) { // current slide
			setSlide( 0, false, false, true )
		} else if ( modulus( currentSlide + 1, totalImages ) === slideIndex ) { // set the next slide
			setSlide( 100, true, false, false )
		} else if ( modulus( currentSlide - 1 , totalImages) === slideIndex ) { // set the previous slide
			setSlide( -100, false, true, false )
		} else { // set other slides that aren't needed
			setSlide( 200, false, false, false )
		}

	}, [ currentSlide, slideIndex, totalImages ])

	return (
		<div
			className={`carousel__slider-item ${ isCurrentSlide ? 'active' : '' } ${ nextSlide ? 'next' : '' } ${ previousSlide ? 'previous' : '' }`}
			style={{ transform: `translateX(${ translateValue }%)` }}>
			<GatsbyImage image={ slide.localFile.childImageSharp.gatsbyImageData } className="carousel__slider-image" alt="" />
		</div>
	)
}