import { gql } from '@apollo/client'

export const PRODUCT_DETAILS = gql`
	fragment productDetails on Product {
		id
		description
		created_at
		price
		name
		slug
		weightInKg
		quantityInStock
		manufacturerPartNumber
		images {
			url
			formats
			width
			height
		}
		manufacturer {
			name
		}
		tags {
			name
		}
		categories {
			name
		}
	}
`