import React, { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';

import { renderProductImages } from '../../helpers/apollo'
import { PRODUCT_DETAILS } from '../../fragments/fragments'

import ProductGrid from '../ProductGrid/ProductGrid'

import { FaSpinner } from 'react-icons/fa'

export default function SortedProducts( props ) {

	const maxWidth = 500
	const limit = 12
	const { sort } = props

	const [ products, setProducts ] = useState( [] )
	const [ loading, setLoading ] = useState( true )

	let variables = { sort: sort, limit: limit }

	// look for apollo fragemnts
	const productQuery = gql`
		query productQuery($start: Int, $limit: Int, $sort: String, $where: JSON) {
			products( start: $start, limit: $limit, sort: $sort, where: $where) {
				...productDetails
			}
		}
		${ PRODUCT_DETAILS }
	`

	let { error, data } = useQuery( productQuery, {
		variables: {
			start: 0,
			...variables
		},
		fetchPolicy: "cache-and-network"
	} )

	useEffect(()=> {
		if ( data ) {
			const updatedProducts = renderProductImages( data.products, maxWidth )
			setProducts( updatedProducts )
			setLoading( false )
		}
	}, [ data ])

	if ( error ) return <p>Error :(</p>

	return (
		<>
			{ loading ? (
				<div className="product-grid__loading-container">
					<h3 className="product-grid__loading-text">Fetching products</h3>
					<FaSpinner className="loader" />
				</div>
			) : (
				<ProductGrid products={ products } />
			) }
		</>
	)
}