export const aspectRatio = ( product, index ) => {
	return product.images[ index ].width / product.images[ index ].height
}

export const srcSet = ( product, index ) => {
	let srcset = []

	for ( let key in product.images[ index ].formats ) {
		const srcString = `${ product.images[ index ].formats[ key ].url } ${ product.images[ index ].formats[ key ].width }w`
		const src = srcString
		srcset.push( src )
	}

	// If images are smaller than 500px, strapi doesn't generate a 'small' format, this is used in the product cards
	// therefore if the small format doesn't exist, we use the initial image
	if ( srcset.length === 1 && !product.images[ index ].formats.small ) {
		const srcString = `${ product.images[ 0 ].url } 500w`
		const src = srcString
		srcset.push( src )
	}

	return srcset.join()
}

export const getFluidImage = ( product, maxWidth, index ) => {
	const result = {}
	result.images = getImages( product, maxWidth, index )

	return result
}

export const getImages = ( product, maxWidth, index) => {
	const images = {
		fallback: {
			sizes: `(max-width: ${ maxWidth }px) 100vw, ${ maxWidth }px`,
			src: product.images[ index ].url,
			srcSet: srcSet( product, index ),
		},
		sources: [ {
			sizes: `(max-width: ${ maxWidth }px) 100vw, ${ maxWidth }px`,
			srcSet: srcSet( product, index ),
			type: 'image/webp'
		} ]
	}

	return images
}

export const renderProductImages = ( products, maxWidth ) => {

	products = JSON.parse( JSON.stringify( products ) )
	const updatedProducts = products.map( product => {
		product.images[0].localFile =  { childImageSharp : { gatsbyImageData : getFluidImage( product, maxWidth, 0 ) } }

		if ( product.images.length > 1 ) {
			product.images[1].localFile =  { childImageSharp : { gatsbyImageData : getFluidImage( product, maxWidth, 1 ) } }
		}

		return product
	} )

	return updatedProducts
}