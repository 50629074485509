import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import { MEASUREMENTS, STYLE_CLS_NAMES } from '../../../constants';

// Create constants file?
const STYLE_CLS_NAMES = {
	ANIMATE_IN: 'animate-in',
	ANIMATE_OUT: 'animate-out',
	IS_ANIMATING: 'is-animating',
	IS_ACTIVE: 'is-active',
	IS_DISABLED: 'is-disabled',
	IS_DOCKED: 'is-docked',
	IS_HIDDEN: 'is-hidden',
	IS_LOADING: 'is-loading',
	IS_OPEN: 'is-open',
	IS_REVEALED: 'is-revealed',
	IS_VISIBLE: 'is-visible',
	U_HIDE: 'u-hide'
}

function Tabs( { children, transitionDuration } ) {

	const DEFAULT_TAB_TRANSITION = 500;
	const tabTransitionDuration = transitionDuration || DEFAULT_TAB_TRANSITION;
	
	const [ activeTab, setActiveTab ] = useState( children[ 0 ] );
	
	const changeTab = ( tab ) => {
		const tabsContent = document.querySelector( '.js-tabs-content' );
		tabsContent.classList.remove( STYLE_CLS_NAMES.ANIMATE_IN );
		tabsContent.classList.add( STYLE_CLS_NAMES.ANIMATE_OUT );
		setTimeout( () => {
			setActiveTab( tab );
		}, tabTransitionDuration / 2 );
		setTimeout( () => {
			tabsContent.classList.add( STYLE_CLS_NAMES.ANIMATE_IN );
			tabsContent.classList.remove( STYLE_CLS_NAMES.ANIMATE_OUT );
		}, tabTransitionDuration );
	};

	return (
		<>
			<div className="tabs">
				<div className="tabs__selectors">
					{ children.map( ( child, i ) => (
						<button
							key={ i }
							className={ `tabs__selector ${ activeTab === child ? 'active' : '' }` }
							onClick={ () => child === activeTab ? null : changeTab( child ) }
						>
							{ child.props.tabName }
						</button>
					) ) }
				</div>
				<div className="tabs__content js-tabs-content">
					{ activeTab.props.children }
				</div>
			</div>
		</>
	);
}

Tabs.propTypes = {
	children: PropTypes.instanceOf( Array ).isRequired,
	transitionDuration: PropTypes.number
};

export default Tabs;