import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'

export default function ProductRibbon( props ) {

	const { quantityInStock, createdAt } = props
	const [ newIn, setNewIn ] = useState( false )

	useEffect(() => {

		if ( createdAt ) {
			// moment() creates todays date and works out the difference in days
			const daysFromCreation = moment().diff( createdAt, 'days' )

			if ( daysFromCreation <= 14 ) {
				setNewIn( true )
			}
		}

	}, [ createdAt ])

	return (
		<>
			{ !quantityInStock &&
				<span className="product-ribbon product-ribbon--sold-out">
					<span className="product-ribbon__text product-ribbon__text--sold-out">Sold out</span>
				</span>
			}
			{ !!newIn && !!quantityInStock &&
				<span className="product-ribbon product-ribbon--new-in">
					<span className="product-ribbon__text product-ribbon__text--new-in">New in</span>
				</span>
			}
		</>
	)
}

ProductRibbon.propTypes = {
	quantityInStock: PropTypes.number,
};
