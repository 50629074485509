import React from "react";
import { graphql } from 'gatsby'

import SEO from "../components/SEO/SEO"
import Carousel from '../components/Carousel/Carousel'
import Tabs from '../components/Tabs/Tabs'
import SortedProducts from '../components/Products/SortedProducts'
import RandomProducts from "../components/Products/RandomProducts";

const HomePage = ({ data, location }) => {

	const totalProductCount = data.strapi.products.length
	const carouselImages = data.strapi.homeCarousel.carouselImages

	return (
		<>
			<SEO title="Home" pathname={ location.pathname } />
			<div className="wrapper">
				<Carousel images={ carouselImages } />
				<Tabs transitionDuration={ 350 }>
					<div tabName="Products">
						<RandomProducts totalProductCount={ totalProductCount } />
					</div>
					<div tabName="New Arrivals">
						<SortedProducts sort="created_at:DESC" />
					</div>
					<div tabName="Best Sellers">
						<SortedProducts sort="quantitySold:DESC" />
					</div>
				</Tabs>
			</div>
		</>
	)
}

export const query = graphql`
	query HomePageQuery {
		strapi {
			products {
				id
			}
			homeCarousel {
				carouselImages {
					url
					id
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`

export default HomePage;