import React, { useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { CartContext } from '../../context/CartContext'

import { formatPrice } from '../../helpers/currency'
import { fromProductSlugToUrl } from '../../helpers/products'
import { GTAddToCart } from '../../helpers/gtagEvents'

import ProductRibbon from '../ProductRibbon/ProductRibbon'

export default function ProductCard( props ) {

	const { product, showCartButton, index, setCount, count } = props
	const { addToCart } = useContext( CartContext );

	const myRef = useRef()

	useEffect( () => {

		if ( ( count - 1 ) === index ) {
			// add quick delay before scrolling down to allow render 1/10th second
			setTimeout(() => {
				if ( myRef.current ) { myRef.current.scrollIntoView({ behaviour: 'smooth', block: 'center' }) }
			}, 500)
			setCount( 0 )
		}
	}, [ count, index, setCount ])

	const toggleAddToCart = ( productData, quantity ) => {
		addToCart( productData, quantity )
		GTAddToCart( productData, quantity ) // Trigger gtag event
	}

	const handleClick = () => {
		if ( setCount ) {
			setCount( index + 1 )
		}
	}

	return (
		<div className="product-card" ref={ myRef }>
			<div className="product-card__container">
				<Link
					to={fromProductSlugToUrl( product.slug )}
					// added on to index as index starts at 0 and apollo starts at 1
					onClick={ () => handleClick() }
					className="product-card__item-link">
					<div className="product-card__item-image-container">
						{ product.images.length > 1 ? (
							<>
								<GatsbyImage className="product-card__item-image product-card__item-image--top" image={ product.images[0].localFile.childImageSharp.gatsbyImageData } alt={ product.name } />
								<GatsbyImage className="product-card__item-image product-card__item-image--bottom" image={ product.images[1].localFile.childImageSharp.gatsbyImageData } alt={ product.name } />
							</>
						) : (
							<GatsbyImage image={ product.images[0].localFile.childImageSharp.gatsbyImageData } className="product-card__item-image product-card__item-image--zoom" alt={ product.name } />
						) }
					</div>
					<div className="product-card__item-meta-wrapper">
						<h3 className="product-card__item-name">{ product.name }</h3>
						<span className="product-card__item-price">{ formatPrice( product.price ) }</span>
					</div>

					<ProductRibbon quantityInStock={ product.quantityInStock } createdAt={ product.created_at } />
				</Link>

				{ showCartButton &&
					<button
						className={`button u-hide-tablet ${ !product.quantityInStock ? 'disabled' : '' } `}
						onClick={ () => toggleAddToCart( product, 1 ) }>
							Add to bag
					</button>
				}
			</div>
		</div>
	)
}

ProductCard.propTypes = {
	product: PropTypes.object,
	showCartButton: PropTypes.bool
};
